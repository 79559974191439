import React from 'react';
import { Root, ConfigProvider } from '@vkontakte/vkui';

import ViewGenerator from './ViewGenerator';

const App = () => {
    return (
        <ConfigProvider isWebView>
            <Root activeView="app">
                <ViewGenerator id="app" />
            </Root>
        </ConfigProvider>
    );
};

export default App;
