import React, { useState, useEffect } from 'react';
import { View } from '@vkontakte/vkui';

import Config from '../../utils/config';
import Router from '../../utils/router';
import EventManager from '../../utils/eventManager';

import PageGenerator from './../PageGenerator';

const config = Config();

const ViewGenerator = ({ id }) => {
    const [activePage, setActivePage] = useState(config.getStartupId());
    const [history, setHistory] = useState([]);

    const router = Router(setActivePage, setHistory);
    const eventManager = EventManager(router);

    useEffect(() => {
        router.setPage(config.getStartupId());
    }, []);

    return (
        <View
            id={id}
            onSwipeBack={() => router.goBack()}
            history={history}
            activePanel={activePage}
        >
            {config.getPanels().map((panelId) => {
                const scheme = config.getPanelById(panelId);
                return (
                    <PageGenerator
                        key={panelId}
                        id={panelId}
                        components={scheme.components || []}
                        eventListener={({ type, data }) => eventManager.run(type, data)}
                    />
                )
            })}
        </View>
    );
};

export default ViewGenerator;
