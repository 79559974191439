const ENDPOINT = 'https://staging.back.mave.vk-admin.com';

export const request = (method, params = {}) => {
    const payload = new FormData();
    Object.keys(params).forEach((param) => payload.append(param, params[param]));

    return fetch(`${ENDPOINT}/${method}`, {
        method: 'POST',
        body: payload,
    })
        .then((res) => res.json());
};