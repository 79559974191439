import bridge from '@vkontakte/vk-bridge';
let instance = null;

class Router {
    activePage = '';
    activePageHook = null;

    history = [];
    historyHook = null;

    constructor(_activePageHook, _historyHook) {
        this.activePageHook = _activePageHook;
        this.historyHook = _historyHook;
    }

    commit() {
        if (this.activePageHook !== null) {
            this.activePageHook(this.activePage);
        }

        if (this.historyHook !== null) {
            this.historyHook(this.history);
        }

        console.log(`[ROUTER]: Commit state: activePage -> "${this.activePage}" and history -> ${JSON.stringify(this.history)}`);
    }

    setPage(id) {
        this.history = [ id ];
        this.activePage = id;
        this.commit();
    }

    openPage(id) {
        if (!id) return;
        this.history.push(id);
        this.activePage = id;
        bridge.send('VKWebAppEnableSwipeBack');
        this.commit();
    }

    goBack() {
        if (this.history.length <= 1) return;
        this.history.pop();
        this.activePage = this.history[this.history.length - 1];
        if (this.history.length === 1) {
            bridge.send('VKWebAppDisableSwipeBack');
        }
        this.commit();
    }
}

export default (...props) => {
    if (instance === null) {
        instance = new Router(...props);
    }

    return instance;
};