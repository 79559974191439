let instance = null;

class Config {
    app = null;
    project = null;
    panels = null;

    loadScheme({ app, project, panels }) {
        this.app = app || null;
        this.project = project || null;
        this.panels = panels || null;
    }

    getPanels() {
        if (this.panels === null) return null;
        return Object.keys(this.panels);
    }

    getPanelById(id = null) {
        if (this.panels === null || id === null) return null;
        return this.panels[id] || null;
    }

    getStartupId() {
        if (this.panels === null) return null;
        let id = null;
        Object.keys(this.panels).map((x) => {
            if (this.panels[x].is_startup) {
                id = x;
            }
        });

        return id;
    }
}

export default () => {
    if (instance === null) {
        instance = new Config();
    }

    return instance;
};