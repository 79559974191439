import bridge from '@vkontakte/vk-bridge';
let instance = null;

class EventManager {
    router = null;

    constructor(_router) {
        this.router = _router;
    }

    run(type, data) {
        console.log(`[EVENT]: Dispatch event with type "${type}" and params ${JSON.stringify(data)}`);
        if (this.router === null) return;

        switch (type) {
            case 'go-back': {
                this.router.goBack();
                break;
            }

            case 'open-panel': {
                if (!data.id) return;
                this.router.openPage(data.id);
                break;
            }

            case 'join-to-group': {
                if (!data.group_id) return;
                bridge.send('VKWebAppJoinGroup', {
                    group_id: Number(data.group_id),
                });
                break;
            }

            default: console.log(`[EVENT]: Unknown event type "${type}"`);
        }
    }
}

export default (...props) => {
    if (instance === null) {
        instance = new EventManager(...props);
    }

    return instance;
};