import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import { utils } from '@mini-core/core';

import App from './containers';
import '@vkontakte/vkui/dist/vkui.css';

import * as API from './utils/api';
import Config from './utils/config';
const config = Config();

window.vk_settings = utils.parseParams(window.location.search);
bridge.subscribe(utils.schemeChanger);

(async () => {
    const info = await API.request('get-app-json', {
        app_id: Number(window.vk_settings.vk_app_id) || '',
    });

    if (!!info.app_json) {
        config.loadScheme(JSON.parse(info.app_json));
        ReactDOM.render(<App />, document.getElementById('root'));
        bridge.send('VKWebAppInit');
    }
})();
