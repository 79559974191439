import React from 'react';
import { Panel } from '@vkontakte/vkui';

import componentByName from '../../utils/componentByName';

const PageGenerator = ({ id, components, eventListener }) => {
    return (
        <Panel id={id}>
            {components.map((comp) => {
                const Component = componentByName(comp.component_name);
                if (Component === null || comp.is_visible === false) return null;

                return React.cloneElement(Component, {
                    ...Component.props,
                    data: comp.data,
                    style: comp.style,
                    actions: comp.actions,
                    eventListener,
                });
            })}
        </Panel>
    );
};

export default PageGenerator;